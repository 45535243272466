var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-actions",
        { attrs: { "action-collapse": "", title: _vm.$t("Filtro") } },
        [
          _c(
            "FormulateForm",
            {
              attrs: { name: "filterService" },
              on: { submit: _vm.filterData },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "name",
                              label: _vm.$t("Nome do Serviço"),
                              placeholder: _vm.$t("Serviço"),
                            },
                            model: {
                              value: _vm.filter.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "name", $$v)
                              },
                              expression: "filter.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("e-button", {
                            staticClass: "mr-1",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              busy: _vm.fetching,
                              text: _vm.$t("Pesquisar"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "service-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              fields: _vm.fields,
              items: _vm.services,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              variant: "link",
                              "toggle-class": "text-decoration-none",
                              "no-caret": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("feather-icon", {
                                        staticClass:
                                          "text-body align-middle mr-25",
                                        attrs: {
                                          icon: "MoreVerticalIcon",
                                          size: "16",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showUpdateServiceSidebar(row)
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "Edit2Icon" },
                                }),
                                _c("span", [_vm._v(_vm._s(_vm.$t("Alterar")))]),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteService(row)
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "TrashIcon" },
                                }),
                                _c("span", [_vm._v(_vm._s(_vm.$t("Remover")))]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "service-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-sidebar", {
        attrs: {
          id: "sidebar-form-servico",
          "aria-controls": "sidebar-form-servico",
          "aria-expanded": _vm.showSidebar,
          "header-class": "text-center",
          "no-close-on-backdrop": "",
          backdrop: "",
          shadow: "",
          right: "",
          width: "500px",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (ref) {
              var hide = ref.hide
              return [
                _c("e-sidebar-header", {
                  attrs: {
                    title: _vm.edit
                      ? _vm.$t("Editar Serviço")
                      : _vm.$t("Novo Serviço"),
                    hide: hide,
                  },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "px-2 py-2" },
                  [
                    _c(
                      "FormulateForm",
                      { ref: "serviceForm", attrs: { name: "serviceForm" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "service-name",
                                    "is-edit": false,
                                    type: "text",
                                    label: _vm.$t("Nome do serviço"),
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.service.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service, "name", $$v)
                                    },
                                    expression: "service.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "4" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "service-cost",
                                    type: "text-number",
                                    currency: "R$",
                                    precision: 2,
                                    label: _vm.$t("Custo"),
                                  },
                                  model: {
                                    value: _vm.service.cost,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service, "cost", $$v)
                                    },
                                    expression: "service.cost",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "4" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "service-price",
                                    type: "text-number",
                                    currency: "R$",
                                    precision: 2,
                                    label: _vm.$t("Valor"),
                                    validation: "min:0.01",
                                    "validation-messages": {
                                      min: "Valor é obrigatório",
                                    },
                                  },
                                  model: {
                                    value: _vm.service.price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service, "price", $$v)
                                    },
                                    expression: "service.price",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "4" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "service-status",
                                    type: "switch",
                                    label: _vm.$t("Serviço Ativo?"),
                                  },
                                  model: {
                                    value: _vm.service.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service, "status", $$v)
                                    },
                                    expression: "service.status",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "service-type",
                                    type: "vue-select",
                                    label: _vm.$t("Tipo"),
                                    placeholder: _vm.$t("Selecione"),
                                    options: ["teste", "teste2", "teste3"],
                                  },
                                  model: {
                                    value: _vm.service.tax.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service.tax, "type", $$v)
                                    },
                                    expression: "service.tax.type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "service-ncm",
                                    type: "vue-select",
                                    label: _vm.$t("NCM"),
                                    placeholder: _vm.$t(
                                      "Digite para pesquisar"
                                    ),
                                    instruction: _vm.$t(
                                      "Digite pelo menos 3 numeros ou letras para iniciar a pesquisa"
                                    ),
                                  },
                                  model: {
                                    value: _vm.service.tax.ncm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service.tax, "ncm", $$v)
                                    },
                                    expression: "service.tax.ncm",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "service-origin",
                                    type: "vue-select",
                                    label: _vm.$t("Origem"),
                                    placeholder: _vm.$t("Selecione"),
                                    options: ["teste", "teste2", "teste3"],
                                  },
                                  model: {
                                    value: _vm.service.tax.origin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service.tax, "origin", $$v)
                                    },
                                    expression: "service.tax.origin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "service-cest",
                                    type: "vue-select",
                                    instruction: _vm.$t(
                                      "Digite pelo menos 3 numeros ou letras para iniciar a pesquisa"
                                    ),
                                    placeholder: _vm.$t(
                                      "Digite para pesquisar"
                                    ),
                                    label: _vm.$t("CEST"),
                                  },
                                  model: {
                                    value: _vm.service.tax.cest,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.service.tax, "cest", $$v)
                                    },
                                    expression: "service.tax.cest",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function (ref) {
              var hide = ref.hide
              return [
                _c(
                  "b-row",
                  { staticClass: "px-2 py-2" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "outline-primary" },
                            on: { click: hide },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Cancelar")) + " ")]
                        ),
                        _c("e-button", {
                          attrs: {
                            variant: "primary",
                            text: _vm.$t("Salvar"),
                            busy: _vm.saving,
                          },
                          on: { click: _vm.saveService },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showSidebar,
          callback: function ($$v) {
            _vm.showSidebar = $$v
          },
          expression: "showSidebar",
        },
      }),
      _c("fab", {
        attrs: { "main-tooltip": _vm.$t("Adicionar Serviço") },
        on: { click: _vm.showCreateServiceSidebar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }