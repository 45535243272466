<template>
  <div>
    <b-card-actions
      action-collapse
      :title="$t('Filtro')"
    >
      <FormulateForm
        name="filterService"
        @submit="filterData"
      >
        <div>
          <b-row>
            <b-col md="6">
              <FormulateInput
                id="name"
                v-model="filter.name"
                :label="$t('Nome do Serviço')"
                :placeholder="$t('Serviço')"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <e-button
                type="submit"
                variant="primary"
                class="mr-1"
                :busy="fetching"
                :text="$t('Pesquisar')"
              />
            </b-col>
          </b-row>
        </div>
      </FormulateForm>
    </b-card-actions>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="service-table"
        show-empty
        responsive
        striped
        class="bordered"
        :fields="fields"
        :items="services"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!-- v-if="$can('Update', 'Service')" -->
              <b-dropdown-item @click="showUpdateServiceSidebar(row)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>{{ $t('Alterar') }}</span>
              </b-dropdown-item>
              <!-- v-if="$can('Delete', 'Service')" -->
              <b-dropdown-item @click="deleteService(row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>{{ $t('Remover') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="service-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-sidebar
      id="sidebar-form-servico"
      v-model="showSidebar"
      aria-controls="sidebar-form-servico"
      :aria-expanded="showSidebar"
      header-class="text-center"
      no-close-on-backdrop
      backdrop
      shadow
      right
      width="500px"
    >
      <template #header="{ hide }">
        <!-- Header -->
        <e-sidebar-header
          :title="edit ? $t('Editar Serviço') : $t('Novo Serviço')"
          :hide="hide"
        />
      </template>

      <template #default>
        <div class="px-2 py-2">
          <FormulateForm
            ref="serviceForm"
            name="serviceForm"
          >
            <b-row>
              <b-col>
                <FormulateInput
                  id="service-name"
                  v-model="service.name"
                  :is-edit="false"
                  type="text"
                  class="required"
                  :label="$t('Nome do serviço')"
                  validation="required"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <FormulateInput
                  id="service-cost"
                  v-model="service.cost"
                  type="text-number"
                  currency="R$"
                  :precision="2"
                  :label="$t('Custo')"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="service-price"
                  v-model="service.price"
                  type="text-number"
                  currency="R$"
                  :precision="2"
                  :label="$t('Valor')"
                  class="required"
                  validation="min:0.01"
                  :validation-messages="{ min: 'Valor é obrigatório' }"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="service-status"
                  v-model="service.status"
                  type="switch"
                  :label="$t('Serviço Ativo?')"
                />
              </b-col>
            </b-row>
            <!-- TODO: retirar mock de options -->
            <b-row>
              <b-col>
                <FormulateInput
                  id="service-type"
                  v-model="service.tax.type"
                  type="vue-select"
                  :label="$t('Tipo')"
                  :placeholder="$t('Selecione')"
                  :options="['teste', 'teste2', 'teste3']"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <FormulateInput
                  id="service-ncm"
                  v-model="service.tax.ncm"
                  type="vue-select"
                  :label="$t('NCM')"
                  :placeholder="$t('Digite para pesquisar')"
                  :instruction="$t('Digite pelo menos 3 numeros ou letras para iniciar a pesquisa')"
                />
                <!-- :options="taxCombos.ncm"
                  @fetchSearch="ncmSearch" -->
              </b-col>
            </b-row>
            <!-- TODO: retirar mock de options -->
            <b-row>
              <b-col>
                <FormulateInput
                  id="service-origin"
                  v-model="service.tax.origin"
                  type="vue-select"
                  :label="$t('Origem')"
                  :placeholder="$t('Selecione')"
                  :options="['teste', 'teste2', 'teste3']"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <FormulateInput
                  id="service-cest"
                  v-model="service.tax.cest"
                  type="vue-select"
                  :instruction="$t('Digite pelo menos 3 numeros ou letras para iniciar a pesquisa')"
                  :placeholder="$t('Digite para pesquisar')"
                  :label="$t('CEST')"
                />
                <!-- :options="taxCombos.cest"
                  @fetchSearch="cestSearch" -->
              </b-col>
            </b-row>
          </FormulateForm>
        </div>
      </template>

      <template #footer="{ hide }">
        <b-row class="px-2 py-2">
          <b-col cols="12">
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="hide"
            >
              {{ $t('Cancelar') }}
            </b-button>
            <e-button
              variant="primary"
              :text="$t('Salvar')"
              :busy="saving"
              @click="saveService"
            />
          </b-col>
        </b-row>
      </template>
    </b-sidebar>

    <!-- v-if="$can('Create', 'Service')" -->
    <fab
      :main-tooltip="$t('Adicionar Serviço')"
      @click="showCreateServiceSidebar"
    />
  </div>
</template>

<script>
import {
  BSidebar,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  ESidebarHeader,
  EButton,
  EPageSizeSelector,
  EPaginationSummary,
  EStatusBadge,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ServiceList',
  components: {
    BSidebar,
    BRow,
    BCol,
    BCardActions,
    BButton,
    BPagination,
    BDropdown,
    BDropdownItem,
    BTable,
    ESidebarHeader,
    EButton,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
  },

  mixins: [],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      filter: this.$store.state.pages?.catalog?.services?.filter,
      fields: [
        {
          label: this.$t('Id'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          class: 'text-left',
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
      ],
    }
  },

  computed: {
    ...mapState('pages/catalog/services', {
      service: 'service',
      services: 'services',
      paging: 'paging',
    }),
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/catalog/services', [
      'fetchServices',
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'setService',
      'cleanService',
    ]),

    async getData() {
      try {
        this.fetching = true
        await this.fetchServices()
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },

    filterData() {
      this.setFilter(this.filter)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreateServiceSidebar() {
      this.cleanService()
      this.edit = false
      this.showSidebar = true
    },

    async showUpdateServiceSidebar(row) {
      this.edit = false
      const { id } = row.item
      console.log(id)
      try {
        // TODO: service GET by id request
        // const { data: service } = await this.$http.get(`/api/services/${id}`)
        // this.service = service

        this.edit = true
        this.showSidebar = true
      } catch (error) {
        this.showGenericError({ error })
      }
    },

    async saveService() {
      this.$refs.serviceForm.showErrors()
      if (this.$refs.serviceForm.isValid) {
        try {
          this.saving = true
          console.log(this.service)
          // TODO: service POST request
          // const body = {
          //   id: this.service.id,
          //   name: this.service.name,
          // }
          // await this.$http({
          //   url: '/api/services',
          //   method: this.edit ? 'PUT' : 'POST',
          //   data: body,
          // })
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    async deleteService(service) {
      const { id } = service.item
      console.log(id)
      try {
        const confirm = await this.confirm()
        if (confirm) {
          // TODO: service DELETE request
          // await this.$http.delete(`/api/services/${id}`)

          this.getData()
          this.showSuccess({ message: this.$t('Serviço removido com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
